import { EntryCountries } from '@/modules/entry-requirements/typing'
import { INotification } from '@/modules/notifications/typing'
import {
    PaidElements,
    SubscriptionType,
    SwitchSubscription,
} from '@/modules/subscribe/typing/enums'
import { Events } from 'jet-tools'

export type AppEvents = {
    event: {
        data: string
    }
    subsribe: {
        isShow: boolean
        method?: 'register' | 'buy'
        isUpdate?: boolean
    }
    guidoBot: { isShow: boolean }
    notifications: { isShow?: boolean; notification?: INotification }
    resetNotification: any
    requrements: { isShow: boolean; data: EntryCountries }
    seaLevel: { level?: number; isShow?: boolean }
    settingsNotifications: { isShow: boolean }
    account: { isShow: boolean }
    questionare: { show: boolean }
    intro: { isShow: boolean; onlyIntro?: boolean }
    greeting: { isShow: boolean }
    requestSubscribe: { isShow: boolean }
    contactUs: { isShow: boolean }
    paidElements: { type?: PaidElements }
    faqs: { isShow: boolean }
    previewTariffs: { isShow: boolean }
    weatherDetailed: { isShow: boolean }
    aboutUs: { isShow: boolean }
    payment: {
        isShow: boolean
        intent?: any
        subscription?: SubscriptionType
        isTrial: boolean
    }
    afterPaid: { isShow: boolean }
    statusPayment: {
        isShow: boolean
        message: string
        status: 'succesed' | 'failed' | 'received'
        isIntro?: boolean
    }
    alert: {
        isShow: boolean
        message: string
        title: string
    }
}
export const appEvents = new Events<AppEvents>()

export type SocketEvents = {
    'notification/new-notification': { data: any }
    stopSessions: { data: any }
    'subscription/new': { data: any }
    'subscription/cancelled': { data: any }
    'subscription/changed': { data: any }
    'subscription/switch-product-application': {
        changeType: SwitchSubscription
    }
}
export const socketEvents = new Events<SocketEvents>()

export type GoogleTagsEvent = {
    successful_registration: {
        registration_method: 'email' | 'facebook' | 'google'
    }
    subscribes: {
        view_subscription_plans: boolean
    }
    selected_subscription: {
        subscription_plan: SubscriptionType
        subscription_price: string
    }
    successful_subscription: {
        subscription_plan: SubscriptionType
        subscription_price: string
    }
}
