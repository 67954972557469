import { useTranslation } from 'react-i18next'
import styles from './style.module.css'
import { $eventVal } from '@/core/helpers'
import { Error } from '../../typography'
import { CSSProperties } from 'react'

interface IProps {
    value: string
    onChangeField: (val: string) => void
    error?: string
    apply: () => void
    style?: CSSProperties
}
export const PromoCodeField = ({
    value,
    onChangeField,
    error,
    apply,
    style,
}: IProps) => {
    const { t } = useTranslation()
    return (
        <div style={style}>
            <p className={styles.label}>
                {t('promoCodeLabel')}{' '}
                <span
                    style={{ fontSize: 14 }}>{`(${t('optionalField')})`}</span>
            </p>

            <div className={styles.container}>
                <input
                    placeholder={t('enterCode')}
                    className={styles.input}
                    value={value}
                    onChange={val => onChangeField($eventVal(val))}
                />

                <button className={styles.action} onClick={apply}>
                    {t('apply')}
                </button>

                <Error
                    style={{
                        position: 'absolute',
                        bottom: -23,
                        left: 0,
                        fontSize: 12,
                        color: '#FC9494',
                    }}>
                    {error}
                </Error>
            </div>
        </div>
    )
}
