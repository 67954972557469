import { Drawer } from 'antd'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'
import { Icon, Loader, PrimaryButton } from '@/core/components'
import { useEventsListener, useResizeWindow } from '@/core/hooks'
import { EntryCountries } from '../../typing'
import _ from 'lodash'
import { getEntryRequirementsReq } from '../../api'
import * as Sentry from '@sentry/react'
import { useLanguageStore } from '@/store'
import { useTranslation } from 'react-i18next'
import html2pdf from 'html2pdf.js'

const contentPadding = 9

export const RequrementsWidget = () => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()
    const { width: widthWindow } = useResizeWindow()
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [countries, setCountries] = useState<EntryCountries>(null)
    const [txt, setTxt] = useState('')
    const [isDb, setIsDb] = useState<boolean>()

    const colorLabels = () => {
        const articleDrawer = document.querySelector('.ant-drawer-content')
        if (articleDrawer)
            articleDrawer.querySelectorAll('p').forEach(p => {
                if (
                    p.textContent.trim().endsWith(':') &&
                    p.style.fontSize === '18px'
                ) {
                    p.style.color = '#0d8f5b'
                    p.classList.add('ends-with-colon')
                }
            })
    }

    const resizeContentTopPadding = () => {
        const container =
            document.querySelector<HTMLElement>('#drawer_container')
        const header = document.querySelector<HTMLElement>('#entry-req-header')
        if (container && header) {
            const headerHeight = header.offsetHeight
            const resultPadding = `${headerHeight + contentPadding}px`
            container.style.paddingTop = resultPadding
        }
    }

    useEffect(() => {
        setTimeout(() => colorLabels(), 10)
    }, [txt])

    useEffect(() => {
        setTimeout(() => resizeContentTopPadding(), 10)
    }, [txt])

    setTimeout(
        () => colorLabels(),

        10,
    )

    setTimeout(
        () => resizeContentTopPadding(),

        10,
    )

    window.addEventListener('resize', resizeContentTopPadding)

    const getEntryRequirements = async () => {
        setIsLoading(true)
        try {
            const { data } = await getEntryRequirementsReq({
                from: countries?.from?.code,
                to: countries?.to?.code,
                citizenship: countries?.citizenship,
                lang,
            })
            setIsDb(data.source === 'db')

            setTxt(data.entryRequirements)
        } catch (error) {
            setTxt('')
            Sentry.captureException(error)
        }
        setIsLoading(false)
    }

    useEventsListener('requrements', source => {
        setOpen(source?.isShow)
        setCountries({
            from: source?.data?.from,
            to: source?.data?.to,
            citizenship: source?.data?.citizenship,
        })
    })

    useEffect(() => {
        if (open) {
            getEntryRequirements()
        }
    }, [open])

    const close = () => setOpen(false)
    const drawerWidth = () => {
        switch (true) {
            case widthWindow < 767:
                return '100dvw'
            case widthWindow < 1024:
                return 'calc(100dvw - 16px)'

            default:
                return '720px'
        }
    }

    const notes = isDb
        ? `* ${t('artificialIntelligenc')}`
        : `* ${t('personalizedData')}`

    const convertHtmlToPdf = (): void => {
        const container = document.createElement('div')
        container.innerHTML = container.innerHTML = `
        <div style="text-align: center; margin-bottom: 20px;">
          
            <h1 style="font-size: 24px; margin-top: 10px;">${`${countries?.from?.name} to ${countries.to?.name}`}</h1>
        </div>
        <div style='line-height:20px;'>${txt}</div>
         
       <p style="color: #717375;
        font-size: 12px;
        font-weight: 500;" matgin-top:40px>${notes}</p>
        
    `
        document.body.appendChild(container)

        const options = {
            margin: [10, 10, 10, 10],
            filename: 'entry-req.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                scale: 2,
                logging: true,
                dpi: 192,
                letterRendering: true,
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }

        html2pdf()
            .from(container)
            .set(options)
            .save()
            .then(() => {
                document.body.removeChild(container)
            })
    }

    return (
        <Drawer
            size='large'
            destroyOnClose
            placement='right'
            closable={false}
            open={open}
            className={styles.articleDrawer}
            styles={{
                body: {
                    padding: 0,
                    paddingBottom: 113,
                    paddingLeft: 1,
                },
                wrapper: {
                    width: drawerWidth(),
                    margin: widthWindow > 767 ? '8.5px 8px' : 0,
                    boxShadow: 'none',
                },
            }}
            onClose={close}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div
                        id='entry-req-header'
                        className={`${styles.header} entry-req-header`}>
                        <p className={styles.label}>
                            {countries?.from?.name} to {countries.to?.name}
                        </p>
                        <div />
                        <div className={styles.buttonsBlock}>
                            <div className={styles.downloadBtn}>
                                <Icon
                                    name='document-download'
                                    size={16}
                                    color='#5E626D'
                                    onClick={convertHtmlToPdf}
                                />
                            </div>

                            <Icon
                                name='x'
                                size={22}
                                color='#5E626D'
                                onClick={close}
                            />
                        </div>
                    </div>

                    <div
                        id='drawer_container'
                        className={styles.drawer_container}>
                        {!txt ? (
                            <div className={styles.sorry_travy}>
                                <p className={styles.sorry_txt}>
                                    {t('pleaseTryAgain')}
                                </p>
                            </div>
                        ) : (
                            <div
                                className={styles.content}
                                dangerouslySetInnerHTML={{ __html: txt }}
                            />
                        )}
                    </div>

                    <div className={styles.footer}>
                        <div className={styles.article_sticky_footer}>
                            <p className={styles.article_check}>{notes}</p>
                        </div>
                        <PrimaryButton
                            label='Download'
                            className={styles.footer_download}
                            labelStyle={{ fontSize: 14 }}
                            onClick={convertHtmlToPdf}
                        />
                    </div>
                </>
            )}

            {isLoading ? null : (
                <div className={styles.sticky_footer}>
                    <div className={styles.article_sticky_footer}>
                        <p className={styles.article_check}>{notes}</p>
                    </div>
                </div>
            )}
        </Drawer>
    )
}
