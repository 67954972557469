import { Taranslates } from '../interfaces'

export const translationDE: Taranslates = {
    // Profile
    cancel: 'Stornieren',
    profile: 'Profil',
    edit: 'Bearbeiten',
    fullname: 'Vollständiger Name',
    countryResidence: 'Wohnsitzland',
    gender: 'Geschlecht',
    man: 'Mann',
    woman: 'Frau',
    email: 'Email',
    citіzenship: 'Staatsangehörigkeit',
    birthday: 'Geburtsdatum',
    citizenship: 'Staatsbürgerschaft',
    infoUseEmail:
        'Bitte geben Sie Ihre E-Mail-Adresse ein, um wichtige Informationen zu erhalten.',
    errorExistEmail: 'E-Mail ist bereits registriert',
    notifications: 'Benachrichtigung',
    chooseNotifications:
        'Wählen Sie die Art der Benachrichtigungen, die Sie erhalten möchten',
    geoConflicts: 'Geopolitische Konflikte',
    internalConflicts: 'Interne soziale Spannungen',
    potentialInsecurity: 'Potenzielle Unsicherheit',
    climate: 'Klimarisiken',
    medical: 'Medizinische Risiken',
    methodNotifications:
        'Wählen Sie die Methode, wie Sie benachrichtigt werden möchten',

    women: 'Frauen',
    bordersEntry: 'Updates zu Grenzübertritten',
    LGBTQ: 'LGBTQ+',
    transportUpdates: 'Updates zum Transport',

    onEmail: 'Per Email',
    pushNotifications: 'Push-Benachrichtigung',

    followCountries: 'Folgen für Länder',
    chatBotSettings: 'Chatbot Einstellungen',
    chatHistoryToEmail: 'Chatbot-Verlauf an deine E-Mail senden',
    allow: 'Erlauben',
    deny: 'Verweigern',
    markAllAsRead: 'Alle als gelesen markieren',

    dangerPotential: 'Potenzielle Gefahr',
    social: 'Soziale Fragen',
    standardLiving: 'Lebensstandard',
    foodDrinking: 'Lebensmittel-und Trinkwasserversorgung',
    humanCapitalDev: 'Entwicklung des Humankapitals',
    medicalSituation: 'Medizinische Situation',

    sureLogout: 'Sind Sie sicher, dass Sie sich',
    toLogout: 'abmelden möchten',
    logout: 'Abmelden',
    back: 'Zurück',

    //Subscribtion settings
    subscription: 'Abonnement',
    cancelSubscribtionBtn: 'Abonnement beenden',
    updateSubscriptionBtn: 'Abonnement aktualisieren',
    yourSubscribtion: 'Dein Abonnement',
    validUntil: 'Gültig bis',
    historySubscription: 'Historie der Abonnements',
    privacyPolicy: 'Datenschutzrichtlinie',
    termsService: 'Nutzungsbedingungen',
    rightsReserved: 'Alle Rechte vorbehalten',
    changePassword: 'Kennwort ändern',
    save: 'Retten',
    accountOverview: 'Kontoübersicht',
    // Overview
    overview: 'Übersicht',
    conflicts: 'Konflikte',
    pandemics: 'Pandemien und Epidemien',
    weather: 'Wetter',
    seaLevel: 'Anstieg des Meeresspiegels',
    requirements: 'Zugangsvoraussetzungen',
    fromCountry: 'Vom Land',
    toCountry: 'Nach Land',
    hightRisk: 'Hoch',
    middleRisk: 'Mitte',
    lowRisk: 'Niedrig',
    notMonitored: 'Nicht überwacht',
    searchCountries: 'Länder suchen...',
    searchCity: 'Stadt suchen',
    viewAll: 'Alle anzeigen',
    allNotifications: 'Alle Benachrichtigungen',
    forCountries: 'Für Länder',
    showMore: 'Mehr anzeigen',
    editInAccount:
        'Sie können diese Einstellungen in Ihrem persönlichen Konto bearbeiten',
    setupNotifications: 'Benachrichtigungen einrichten',
    errorSelectCountry: 'Sie müssen mindestens 1 Land auswählen',
    sureSubscribtion: 'Möchten Sie wirklich',
    cancelSubscribtion: 'Ihr Abonnement kündigen?',
    unsubscribe: 'Abonnement kündigen',
    canSubscribe: 'Sie können sich jederzeit wieder anmelden',
    successCancelSubscribtion:
        'Anfrage erhalten. Sie haben bis zum Ende des aktuellen Abonnements Zugriff.',
    successUpdateAccount: 'Kontoaktualisierung erfolgreich',
    selectGender: 'Geschlecht auswählen',
    disabledEmail: 'E-Mail kann nicht bearbeitet werden',
    findCountries: 'Länder suchen',
    subscribtionNotSelected: 'Nicht ausgewählt',
    searchRequirementsDescription:
        'Wir helfen Ihnen, überall auf der Welt sicher zu bleiben.',
    followCountriesDescription:
        'Sie müssen mindestens 1 und höchstens 6 Länder auswählen',

    // Instruction
    conflictsInstruction:
        'Bevor Sie ein neues Land besuchen, sollten Sie sich mit mehreren Fragen beschäftigen. An erster Stelle steht die Sicherheit. Mögliche bewaffnete Konflikte würden Ihren Plänen beeinflussen. Wählen Sie die Kategorie Konflikte und finden Sie alle Orte, an denen die Gefahr von bewaffneten Konflikten besteht.',
    socialIstruction:
        'Informationen zu gesellschaftlichen Themen, die Ihre Sicherheit beeinträchtigen können. Das Verfahren ist dasselbe: Wählen Sie eines der Themen aus, die Farbe des Landes zeigt Ihnen den Risikograd an, und ein Klick auf das Land öffnet die entsprechenden Informationen. ',
    healthInstruction:
        'Das gleiche Verfahren gilt für den Bereich Medizin. Informieren Sie sich über mögliche medizinische Faktoren und deren Auswirkungen auf Ihre Sicherheit.',
    climateInstruction:
        'Überprüfen Sie das Wetter und die Möglichkeit extremer Naturereignisse im Land.',
    requirementsInstruction:
        'Möchten Sie wissen, wie Sie in das Land einreisen können? Gehen Sie zum Abschnitt Einreisebestimmungen. Wählen Sie das Abreise- und Ankunftsland aus der Liste aus, klicken Sie auf „Suchen“ und Sie erhalten alle erforderlichen Daten, angefangen bei den Visabestimmungen bis hin zur Aufenthaltsdauer im Land.',
    legendInstruction:
        'Die rote Farbe des Landes auf der Karte signalisiert eine extrem hohe Gefahr. In unserem Fall die eines bewaffneten Konflikts. Gelb steht für eine erhebliche, aber nicht unmittelbare Gefahr. Grün steht für eine sichere Zone. Grau bedeutet, dass es in dieser Welt so viel zu wissen gibt!',
    legend: 'Legende',
    chatBotInstruction:
        'Brauchst du spezielle Hilfe? Kaufen Sie Extra frag Travy, deinen KI-unterstützten Kumpel! Für das erste Kennenlernen musst du eine kurze Umfrage ausfüllen, damit Travy dich persönlicher ansprechen kann. Dann kannst du Travy deine Fragen stellen!',
    chatBot: 'Chat-bot',
    map: 'Karte',
    mapInstructions:
        'In diesem Abschnitt wählen Sie eines der folgenden Themen aus: aktuelle geopolitische Konflikte interne soziale Gefahren potenzielle Gefahr eines bewaffneten Konflikts. Die Farbe zeigt den Grad des Risikos für jedes dieser Themen an. Klicken Sie dann auf ein Land und lesen Sie aktuelle Informationen über die Einzelheiten des Konflikts',
    notificationsInstruction:
        'Erhalten Sie Echtzeitwarnungen über Gefahren in ausgewählten Ländern',
    personalAccount: 'Persönlicher Account',
    accountInstruction:
        'Möchten Sie informiert bleiben? Abonnieren Sie die Benachrichtigungen in Ihrem Profil.',

    // Notes
    artificialIntelligenc:
        'Der Artikel wird durch künstliche Intelligenz generiert. Bitte überprüfen Sie wichtige Daten',
    personalizedData: 'Aktuelle personalisierte Daten',
    search: 'Suche',

    consideration: 'Der Chatbot berücksichtigt Ihre Antworten nicht.',
    quit: 'Beenden',

    // Tariffs
    basic: 'Basic',
    discoverBasic: 'Entdecken Sie Basic',
    discoverExtra: 'Entdecken Sie Extra',
    conflictsWorldwide: 'Weltweit Konflikte',
    socialIssues: 'Soziale Themen',
    healthLabel: 'Gesundheit',
    climateLabel: 'Klima',
    monthlyDataUpdates: 'Tägliche und monatliche Update',
    visaEntry: 'Generell Einreisebestimmungen',
    emergencyPush: 'Notfall-Push-Benachrichtigung',
    freeTrial: '3 Tage kostenlos',
    buyNow: 'Jetzt kaufen',
    extra: 'Extra ',
    tailoredAI: 'Personalisierter KI-Assistent',
    sightsAI: 'KI-Berater für Sehenswürdigkeiten',
    hotelTicketsAI: 'KI-Berater für Hotel und Tickets',
    personalizedVisa: 'Individuelle Einreisebestimmung',
    noTrial: 'Keine kostenlose Probeversion, Zahlung im Voraus erforderlich',
    basic3: 'Basis 3 Monate',
    extra3: 'Extra 3 Monate',
    popular: 'Am beliebtesten',
    getStarted: 'Anfangen',
    titleSubscribes: 'Entdecken Sie die Welt mit SafeTravy',
    benefits: 'Vorteile',
    month: 'monat',
    buy: 'Kaufen',
    currentTariff: 'Aktueller Tarifplan',
    notHistory: 'Noch kein Verlauf Ihrer Abonnements',

    newSubscribtionMessage:
        'Anfrage erhalten. Das neue Abonnement wird innerhalb weniger Minuten aktiviert. Anschließend Seite neu laden.',
    upgradeSubscribtionMessage:
        'Anfrage erhalten. Das neue Abonnement wird innerhalb weniger Minuten aktiviert. Anschließend Seite neu laden.',
    downgradeSubscribtionMessage:
        'Anfrage erhalten. Das neue Abonnement wird nach Ablauf des aktuellen Abonnements aktiviert.',
    tariffPlans: 'Tarifpläne',
    womenLgbt: 'Personalisierte Informationen für Frauen und LGBTQ+',
    fullSubsctiption: 'Kaufen Sie ein Vollabonnement',
    subscriptionPlans: 'Abonnementpläne',
    titleTariffs: 'Erleben Sie Reise- und Lebenssicherheit',
    withSafeTravy: 'Mit SafeTravy',
    buyExtra: 'Extra kaufen abonnieren',

    // Questionare
    titleTravy: 'Hallo! Ich bin Travy',
    chooseVariant: 'Wählen Sie, was Sie wollen',
    personalisedAnswer:
        'Nehmen Sie an der Umfrage teil, um persönlichere Antworten zu erhalten',
    yourQuestions: 'Stellen Sie Ihre Frage',
    purpouse: 'Was ist der Zweck Ihrer Reise?',
    leisure: 'Freizeit',
    business: 'Business',
    familyAffairs: 'Familienangelegenheiten/persönliche Angelegenheiten',
    next: 'Nächste',

    transportationKind:
        'Welche Art von Transportmittel bevorzugen Sie, um an Ihr Ziel zu gelangen?',
    landTransportation: 'Landverkehr',
    airTransportation: 'Lufttransport',
    seaTransportation: 'Seetransport',

    accommodation: 'Welche Art von Unterkunft bevorzugen Sie?',
    hotel: 'Hotel',
    apartament: 'Appartement',
    hostel: 'Hostel',
    holidayHome: 'Ferienhaus',

    activities: 'Für welche Art von Aktivitäten interessieren Sie sich?',
    historicalSites: 'Besichtigung historischer Stätten',
    museumsAndTheatres: 'Museen und Theater',
    parks: 'Naturparks und Naturschutzgebiete',
    concerts: 'Konzerte und Auftritte',
    attractions: 'Attraktionen und Sehenswürdigkeiten',
    shopping: 'Geschäfte und Märkte',
    nightlife: 'Nachtleben',
    sports: 'Sportliche Aktivitäten',
    culinary: 'Restaurants und kulinarische Touren',

    considerations:
        'Haben Sie besondere Anforderungen oder Überlegungen, die wir beachten sollten?',
    dietary: 'Ernährungsbedürfnisse',
    mobilityAssistance: 'Mobilitätshilfe',
    medicalConditions:
        'Medizinische Bedingungen oder gesundheitliche Erwägungen',
    sensoryAccommodations: 'Sensorische Anpassungen',
    no: 'Nein',

    specificFears:
        'Haben Sie besondere Ängste oder Bedenken, die wir kennen sollten?',
    heights: 'Höhenangst',
    fearSpaces: 'Angst vor geschlossenen oder überfüllten Räumen',
    flying: 'Flugangst',
    water: 'Angst vor Wasser- oder Seereisen',
    animals: 'Angst vor Insekten oder Tieren',
    culturalDifferences: 'Besorgnis über kulturelle Unterschiede',

    cultureAndCuisine:
        'Interessieren Sie sich für die lokale Kultur und Küche?',
    yes: 'Ja',

    thank: 'Vielen Dank!',
    thanYouMessage:
        'Ihre Antworten werden berücksichtigt, wenn der Chatbot Ihre Fragen beantwortet.',

    enterMessage: 'Geben Sie Ihre Nachricht ein',
    canHeplYou: 'Wie kann ich dir helfen?',
    notesChatBot:
        'Informationen werden durch künstliche Intelligenz generiert. Bitte überprüfen Sie wichtige Daten.',

    // Auth
    titleSignInStart: 'Willkommen zurück bei',
    titleSignInEnd: 'SafeTravy 👋',
    password: 'Passwort',
    continue: 'Weitermachen',
    forgotpassword: 'Passwort vergessen',
    signInGoogle: 'Mit Google anmelden',
    signInFacebook: 'Mit Facebook anmelden',
    haventAccount: 'Neu bei SaveTravy? ',
    createAccount: 'Konto erstellen',
    signUp: 'Anmeldung',
    errorEmailPassword: 'Passwort oder E-Mail falsch. Versuchen Sie es erneut!',

    titleSignUp: 'Hey, hallo! 👋',
    confirmPassword: 'Passwort bestätigen',
    agreeTerms: 'Mit Ihrer Anmeldung stimmen Sie den',
    terms: 'Allgemeinen Geschäftsbedingungen',
    policy: 'Datenschutzrichtlinie zu',
    signUpGoogle: 'Bei Google anmelden',
    signUnFacebook: 'Bei Facebook anmelden',
    existAccount: 'Haben Sie bereits einen Account?',

    signIn: 'Anmelden',
    forgotPasswordTitle: 'Passwort vergessen',
    messageSendCodeStart:
        'Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.',
    messageSendCodeEnd: 'Wir senden Ihnen einen Code',
    rememberPassword: 'Erinnern Sie sich an Ihr Passwort?',

    resetPassword: 'Passwort zurücksetzen',
    verificationCodeMessage:
        'Geben Sie den Verifizierungscode ein, den Sie in der E-Mail erhalten',
    sendAgain: 'Erneut senden',
    notReceiveCode: 'Sie haben keinen Code erhalten?',
    resend: 'Erneut senden',
    reset: 'Zurücksetzen',
    confirm: 'Bestätigen',

    newPasswordTitle: 'Neues Passwort eingeben',
    newPasswordMessage: 'Neues Passwort eingeben und bestätigen',
    newPassword: 'Neues Passwort',
    confirmNewPassword: 'Neues Passwort bestätigen',
    passwordChangedSuccessfully: 'Das Passwort wurde erfolgreich geändert',
    sunrise: 'Sonnenaufgang',
    sunset: 'Sonnenuntergang',
    realFeel: 'Fühlt sich an',
    wind: 'Wind',
    pressure: 'Druck',
    humidity: 'Feuchtigkeit',
    detailedForecast: 'Detaillierte Prognose',
    night: 'Nacht',
    morning: 'Morgen',
    day: 'Tag',
    evening: 'Abend',
    temperature: 'Temperatur',
    windMetric: 'Wind, m/sec',
    feelsLike: 'Fühlt sich an',
    viceMetric: 'Schraubstock, mm',
    humidityTable: 'Feuchtigkeit,%',
    precipitationProbability: 'Niederschlagswahrscheinlichkeit',
    dangerWarning: 'Warnung vor Gefahren',
    UVIndex: 'UV Index',
    humidityForecast: 'Feuchtigkeit:',
    emptyAlert: 'Es scheint, als sei alles ruhig',
    emptyAlertCurrentCity: 'in der heutigen Stadt',
    authLogoTextStart: 'Die Welt Besser Verstehen,',
    authLogoTextEnd: 'Sicherer Reisen ',

    // subscribe
    modalTitle: 'Rechuest erhielt',
    subtitleModal:
        'Das neue Abonnement wird innerhalb weniger Minuten aktiviert. Aber die Handlungen sind richtig.',
    contactUsTitle: 'Brauchen Sie Hilfe?',
    //FAQ
    titleFAQ: 'Häufig gestellte Fragen',
    subtitleFAQ:
        'Um den KI-Chatbot zu verwenden, ist ein vollständiges Abonnement erforderlich. Wählen Sie in der Zwischenzeit aus, was Sie als Nächstes tun möchten.',
    aboutPlatform: 'Über die Webplattform',
    fullSubscription: 'Volles Abonnement',
    fullSubscriptionRequired: 'Ein vollständiges Abonnement ist erforderlich',
    LGBTInstruction:
        'Hier bieten wir Ihnen wichtige Einblicke in den rechtlichen Schutz, kulturelle Normen, LGBTQ+-freundliche Orte und Sicherheitstipps, damit Sie mit Selbstvertrauen und Stolz reisen können.🌈✨',
    womenInstruction:
        'Als Frau zu reisen ist mit besonderen Überlegungen verbunden, und wir sind hier, um Ihnen zu helfen. In diesem Abschnitt finden Sie wichtige Hinweise zu persönlicher Sicherheit, kulturellem Bewusstsein, Gesundheitstipps und zur Vermeidung von Betrug - alles, was Sie für eine sichere und selbstbewusste Reise benötigen. Gehen Sie unbesorgt auf Entdeckungsreise und machen Sie das Beste aus Ihrem Abenteuer.',
    greetingTitle: 'Willkommen bei Safe Travy!',
    greetingContent:
        'Wir helfen Ihnen, überall auf der Welt sicher zu bleiben. Sie können unsere interaktiven Tipps nutzen, um mehr über die Funktionen der Plattform zu erfahren, oder sie überspringen und den Dienst sofort nutzen',
    showTips: 'Tipps anzeigen',
    skip: 'Überspringen',
    done: 'Erledigt',
    viewMore: 'Mehr anzeigen',
    aboutUs: 'Über uns',
    exploreExtra: 'Extra-Plan erkunden',
    exploreNow: 'Jetzt erkunden',
    apply: 'Anwenden',
    monthly: 'Monatlich',
    whatIncludes: 'Was ist enthalten',
    allBasic: 'Alles in Basic',
    quaterly: 'Vierteljährlich',
    grandTrial: 'Testzugriff gewähren',
    vat: 'MwSt.',
    subscriptionAgreement:
        'Wenn Sie auf „Kaufen“ klicken, stimmen Sie zu, dass Ihnen, sofern Sie nicht mindestens 24 Stunden vor Ablauf der 3-tägigen Testversion kündigen, [der volle Abonnementpreis] pro Monat oder pro 3 Monate, basierend auf Ihrem ausgewählten Plan, in Rechnung gestellt wird, bis Sie kündigen. Sie können Ihr Abonnement jederzeit kündigen, indem Sie unserem Kundensupportteam eine E-Mail an support@safetravy.com senden oder über Ihre Kontoeinstellungen.',
    subscriptionAgreementLabel: 'Abonnementvereinbarung',
    agree: 'Ich stimme zu',
    consent:
        'Indem Sie Ihr Abonnement bestätigen, gestatten Sie SafeTravy, diese und zukünftige Zahlungen gemäß den Bedingungen von Ihrer Karte abzubuchen.',
    paymentSecured: 'Sichere Zahlung',
    totalSubscriptionPeriod: 'gesamtbetrag für den Zeichnungszeitraum',
    promoCodeLabel: 'Aktionscode',
    fullAccess: 'Gibt Ihnen vollen Zugriff auf die Plattform.',
    subscribeTo: 'Abonnieren Sie Safetravy',
    then: 'Dann',
    threeDaysFree: '3 Tage gratis',
    enterCode: 'Code eingeben',
    codeNotValid: 'Codehinweis gültig',
    payCard: 'Mit Karte bezahlen',
    cardNumber: 'Kartennummer',
    expiryDate: 'Verfallsdatum',
    startTrial: 'Testversion starten',
    insuranceAction: 'Promo-Code jetzt verwenden',
    insuranceTitle: 'Reiseversicherung Angebot!',
    insuranceTxt:
        'Mit Ihrem Abonnement erhalten Sie einen $9 Promo-Code für 6 Tage weltweite Reiseversicherung. Details finden Sie in Ihrer E-Mail!',
    unpaidSubscription:
        'Ups, es scheint, dass Ihr Abonnement nicht bezahlt wurde. Kündigen Sie es, wenn Sie den Tarif wechseln möchten.',
    labelUnpaidSubscription: 'Unbezahltes Abonnement',
    promoCodeDescription:
        'Promo-Codes gelten nur für Ihr aktuelles Abonnement und können bei einem Wechsel des Abonnements nicht angewendet werden. Wir arbeiten aktiv an der Verbesserung unserer Plattform, um dieses Problem zu beheben und Ihnen die bestmögliche Erfahrung zu bieten.',
    warning: 'Achtung',
    optionalField: 'Dieses Feld ist optional',
    pleaseTryAgain: 'Bitte versuchen Sie es später erneut.',
    current: 'Aktuell',
    activeUntil: 'Aktiv bis',
    now: 'Jetzt',
    transactionFailedFull:
        'Transaktion fehlgeschlagen! Ihr Abonnement konnte nicht aktiviert werden.',
    checkPayment:
        'Bitte überprüfen Sie Ihre Zahlungsdetails und versuchen Sie es erneut.',
    successPaidSubscribtion: 'Abonnement erfolgreich aktiviert.',
    usePlatform: 'Sie können die Plattform nutzen. Nur zu!',
    gotYou: 'Hab dich',
    errorFileSize:
        'Die von Ihnen ausgewählte Datei ist zu groß. Nur Dateien mit einer Größe von 1 MB oder weniger sind erlaubt. Bitte wählen Sie eine kleinere Datei.',
    alsoTo: 'bezieht sich auch auf',
}
