import { Languages } from '@/core/typing'

interface IFaQItem {
    id: string
    question: string
    answer: string
}

export const translateFAQEN: IFaQItem[] = [
    {
        id: '1',
        question: 'What is SafeTravy?',
        answer: 'SafeTravy is an all-in-one guidance solution for travelers. Our platform showcases informative content in the areas of climate, social, and medical situations around the world as well as visa and entry requirements. Besides informative content on world interactive map, SafeTravy offers personalized AI assistance for every type of travel and real-time push notifications covering emergency alerts on risks in the location of our customers.',
    },
    {
        id: '2',
        question: 'Is SafeTravy free to use?',
        answer: 'You can explore SafeTravy with a free trial to experience its features. After the trial, you can choose a subscription plan that suits your travel needs.',
    },
    {
        id: '3',
        question: 'How often is information updated on the platform?',
        answer: 'Country-specific information is updated monthly, while push notifications about dangers and weather conditions are delivered in real time.',
    },
    {
        id: '4',
        question: 'What do the different colors on the map mean?',
        answer: 'The colors reflect the level of risk. Red indicates a high risk, yellow indicates moderate risk, green means safe, and gray means no data is available.',
    },
    {
        id: '5',
        question:
            'How can I find out visa requirements and allowed stay duration to the destination country?',
        answer: 'Simply go to the “Entry Requirements” section, select your departure and destination countries, and click “Search.” You’ll see all the necessary details, including visa requirements and permitted stay durations.',
    },
    {
        id: '6',
        question:
            'How does the travel insurance promo code for SafeTravy subscribers work?',
        answer: 'As a SafeTravy subscriber, you receive a promo code for $9 off Auras travel insurance. This discount covers up to 6 days of worldwide protection for your next trip. If your trip is longer or includes extreme activities, the discount still applies—you just pay the balance. Auras travel insurance includes: Worldwide Coverage: Valid in any country. Visa and Embassy Approval: Meets visa and embassy requirements. Suitable for All Ages: Covers travelers aged 1 to 100. 24/7 Support: Multilingual assistance anytime, anywhere. To use your promo code, simply apply it during your insurance purchase. It’s easy and ensures you’re protected for every journey! Where to find your promo code: Your promo code will be sent to you via email. If you can’t find it in your inbox, don’t forget to check your Promotions or Ads folder — it might be waiting for you there!',
    },
    {
        id: '7',
        question: 'How do I use the chatbot?',
        answer: 'You can interact with Travy, our AI assistant, to get answers to your travel-related questions. For a more personalized experience, complete a quick survey when using Travy to tailor responses and recommendations to your specific needs.',
    },

    {
        id: '8',
        question:
            'Can I receive alerts about any danger in the location I`m staying in?',
        answer: 'Yes, you can! Simply subscribe to real-time notifications in your profile, and you’ll receive alerts about any dangers or emergencies in your current location.',
    },
]

export const translatesFAQDE: IFaQItem[] = [
    {
        id: '1',
        question: 'Was ist SafeTravy?',
        answer: 'SafeTravy ist eine All-in-One-Reiseberatungsplattform zur Unterstützung von Reisenden. Sie bietet aktuelle Informationen zu klimatischen, sozialen und medizinischen Situationen weltweit sowie zu Visa- und Einreisebestimmungen. Darüber hinaus bietet SafeTravy personalisierte KI-Hilfe, Echtzeit-Notfallwarnungen und Push-Benachrichtigungen, die auf Ihre Reisebedürfnisse zugeschnitten sind.',
    },
    {
        id: '2',
        question: 'Ist die Nutzung von SafeTravy kostenlos?',
        answer: 'Sie können SafeTravy mit einer kostenlosen Testversion ausprobieren, um seine Funktionen kennenzulernen. Nach der Testphase können Sie einen Abonnementplan wählen, der Ihren Reisebedürfnissen entspricht.',
    },
    {
        id: '3',
        question:
            'Wie oft werden die Informationen auf der Plattform aktualisiert?',
        answer: 'Länderspezifische Informationen werden monatlich aktualisiert, während Push-Benachrichtigungen über Gefahren und Wetterbedingungen in Echtzeit geliefert werden.',
    },
    {
        id: '4',
        question: 'Was bedeuten die verschiedenen Farben auf der Karte?',
        answer: 'Die Farben spiegeln den Grad des Risikos wider. Rot steht für ein hohes Risiko, Gelb für ein mittleres Risiko, Grün für ein sicheres Risiko und Grau bedeutet, dass keine Daten verfügbar sind.',
    },
    {
        id: '5',
        question:
            'Wie kann ich mich über die Visabestimmungen und die zulässige Aufenthaltsdauer für das Zielland informieren?',
        answer: 'Gehen Sie einfach zum Abschnitt „Einreisebestimmungen“, wählen Sie Ihr Abreise- und Zielland aus und klicken Sie auf „Suchen“. Sie erhalten dann alle erforderlichen Angaben, einschließlich der Visabestimmungen und der zulässigen Aufenthaltsdauer.',
    },
    {
        id: '6',
        question:
            'Wie funktioniert der Reiseversicherungs-Promo-Code für SafeTravy-Abonnenten?',
        answer: 'Als SafeTravy-Abonnent erhalten Sie einen Promo-Code für $9 Rabatt auf die Auras-Reiseversicherung. Dieser Rabatt deckt bis zu 6 Tage weltweiten Schutz für Ihre nächste Reise ab. Wenn Ihre Reise länger ist oder extreme Aktivitäten beinhaltet, gilt der Rabatt trotzdem - Sie zahlen nur den Restbetrag. Die Auras-Reiseversicherung umfasst: Weltweite Deckung: Gültig in jedem Land. Visa- und Botschaftsgenehmigung: Erfüllt die Anforderungen von Visa und Botschaften. Geeignet für alle Altersgruppen: Deckt Reisende von 1 bis 100 Jahren ab. 24/7-Unterstützung: Mehrsprachige Unterstützung zu jeder Zeit und an jedem Ort. Um Ihren Promo-Code zu verwenden, geben Sie ihn einfach beim Abschluss Ihrer Versicherung ein. Es ist ganz einfach und gewährleistet, dass Sie auf jeder Reise geschützt sind! Wo Sie Ihren Promo-Code finden: Sie erhalten Ihren Promo-Code per E-Mail. Wenn Sie ihn nicht in Ihrem Posteingang finden, vergessen Sie nicht, in Ihrem Ordner „Aktionen“ oder „Ankündigungen“ nachzusehen.',
    },
    {
        id: '7',
        question: 'Wie kann ich den Chatbot nutzen?',
        answer: 'Sie können mit Travy, unserem KI-Assistenten, interagieren, um Antworten auf Ihre reisebezogenen Fragen zu erhalten. Für ein noch persönlicheres Erlebnis können Sie bei der Nutzung von Travy eine kurze Umfrage ausfüllen, um Antworten und Empfehlungen auf Ihre speziellen Bedürfnisse zuzuschneiden.',
    },
    {
        id: '8',
        question:
            'Kann ich Warnungen über Gefahren an dem Ort erhalten, an dem ich mich aufhalte?',
        answer: 'Ja, das können Sie! Abonnieren Sie einfach Echtzeit-Benachrichtigungen in Ihrem Profil, und Sie werden über alle Gefahren oder Notfälle an Ihrem aktuellen Standort informiert.',
    },
]

export const translatesFAQES: IFaQItem[] = [
    {
        id: '1',
        question: '¿Qué es SafeTravy?',
        answer: 'SafeTravy es una plataforma integral de orientación para viajeros. Proporciona información actualizada sobre situaciones climáticas, sociales y médicas en todo el mundo, así como requisitos de visado y entrada. Además, SafeTravy ofrece asistencia AI personalizada, alertas de emergencia en tiempo real y notificaciones push adaptadas a sus necesidades de viaje.',
    },
    {
        id: '2',
        question: '¿Es gratis utilizar SafeTravy?',
        answer: 'Puedes explorar SafeTravy con una prueba gratuita para experimentar sus funciones. Después de la prueba, puedes elegir un plan de suscripción que se adapte a tus necesidades de viaje.',
    },
    {
        id: '3',
        question:
            '¿Con qué frecuencia se actualiza la información en la plataforma?',
        answer: 'La información específica de cada país se actualiza mensualmente, mientras que las notificaciones push sobre peligros y condiciones meteorológicas se envían en tiempo real.',
    },
    {
        id: '4',
        question: '¿Qué significan los diferentes colores del mapa?',
        answer: 'Los colores reflejan el nivel de riesgo. El rojo indica un riesgo elevado, el amarillo un riesgo moderado, el verde significa seguro y el gris significa que no hay datos disponibles.',
    },
    {
        id: '5',
        question:
            '¿Cómo puedo averiguar los requisitos de visado y la duración permitida de la estancia en el país de destino?',
        answer: 'Sólo tiene que ir a la sección «Requisitos de entrada», seleccionar los países de salida y destino y hacer clic en «Buscar». Verá todos los detalles necesarios, incluidos los requisitos de visado y la duración permitida de la estancia.',
    },
    {
        id: '6',
        question:
            '¿Cómo funciona el código promocional del seguro de viaje para suscriptores de SafeTravy?',
        answer: 'Como suscriptor de SafeTravy, recibirás un código promocional de 9 $ de descuento en el seguro de viaje Auras. Este descuento cubre hasta 6 días de protección en todo el mundo para su próximo viaje. Si su viaje es más largo o incluye actividades extremas, el descuento sigue siendo aplicable-sólo tiene que pagar el saldo. El seguro de viaje Auras incluye: Cobertura Mundial: Válido en cualquier país. Aprobación de visados y embajadas: Cumple los requisitos de visados y embajadas. Apto para todas las edades: Cubre a viajeros de 1 a 100 años. Asistencia 24/7: Asistencia multilingüe en cualquier momento y lugar. Para utilizar su código promocional, sólo tiene que aplicarlo durante la compra de su seguro. Es fácil y le garantiza protección en cada viaje. Dónde encontrar su código promocional: Recibirás tu código promocional por correo electrónico. Si no lo encuentras en tu bandeja de entrada, no olvides revisar tu carpeta de Promociones o Anuncios, ¡puede que te esté esperando allí!',
    },
    {
        id: '7',
        question: '¿Cómo se utiliza el chatbot?',
        answer: 'Puedes interactuar con Travy, nuestro asistente de IA, para obtener respuestas a tus preguntas relacionadas con viajes. Para una experiencia más personalizada, completa una encuesta rápida cuando utilices Travy para adaptar las respuestas y recomendaciones a tus necesidades específicas.',
    },
    {
        id: '8',
        question:
            '¿Puedo recibir alertas sobre cualquier peligro en el lugar en el que me alojo?',
        answer: 'Sí que puedes. Sólo tienes que suscribirte a las notificaciones en tiempo real en tu perfil, y recibirás alertas sobre cualquier peligro o emergencia en tu ubicación actual.',
    },
]

export const translatesFAQFR: IFaQItem[] = [
    {
        id: '1',
        question: "Qu'est-ce que SafeTravy?",
        answer: "SafeTravy est une plateforme d'orientation tout-en-un conçue pour aider les voyageurs. Elle fournit des informations actualisées sur les conditions climatiques, sociales et médicales dans le monde entier, ainsi que sur les exigences en matière de visa et d'entrée sur le territoire. En outre, SafeTravy offre une assistance personnalisée par IA, des alertes d'urgence en temps réel et des notifications push adaptées à vos besoins de voyage.",
    },
    {
        id: '2',
        question: "L'utilisation de SafeTravy est-elle gratuite?",
        answer: "Vous pouvez explorer SafeTravy avec un essai gratuit pour découvrir ses fonctionnalités. Après l'essai, vous pouvez choisir une formule d'abonnement adaptée à vos besoins de voyage.",
    },
    {
        id: '3',
        question:
            'À quelle fréquence les informations sont-elles mises à jour sur la plateforme?',
        answer: 'Les informations spécifiques à chaque pays sont mises à jour mensuellement, tandis que les notifications push concernant les dangers et les conditions météorologiques sont diffusées en temps réel.',
    },
    {
        id: '4',
        question: 'Que signifient les différentes couleurs de la carte?',
        answer: 'Les couleurs reflètent le niveau de risque. Le rouge indique un risque élevé, le jaune un risque modéré, le vert une sécurité et le gris une absence de données.',
    },
    {
        id: '5',
        question:
            "Comment puis-je connaître les conditions d'obtention d'un visa et la durée de séjour autorisée dans le pays de destination?",
        answer: "Il vous suffit de vous rendre dans la section « Conditions d'entrée », de sélectionner vos pays de départ et de destination, puis de cliquer sur « Rechercher ». Vous obtiendrez tous les détails nécessaires, y compris les exigences en matière de visa et les durées de séjour autorisées.",
    },
    {
        id: '6',
        question:
            "Comment fonctionne le code promo de l'assurance voyage pour les abonnés de SafeTravy ?",
        answer: "En tant qu'abonné de SafeTravy, vous recevez un code promo pour 9 $ de réduction sur l'assurance voyage Auras. Cette réduction couvre jusqu'à 6 jours de protection dans le monde entier pour votre prochain voyage. Si votre voyage est plus long ou comprend des activités extrêmes, la réduction s'applique toujours - vous n'avez qu'à payer le solde. L'assurance voyage Auras comprend Une couverture mondiale : Valable dans tous les pays. Approbation des visas et des ambassades : Répond aux exigences en matière de visa et d'ambassade. Convient à tous les âges : Couvre les voyageurs âgés de 1 à 100 ans. Assistance 24 heures sur 24, 7 jours sur 7 : Assistance multilingue à tout moment et en tout lieu. Pour utiliser votre code promo, il vous suffit de l'appliquer lors de la souscription de votre assurance. C'est facile et cela vous permet d'être protégé à chaque voyage! Où trouver votre code promo : Vous recevrez votre code promo par e-mail. Si vous ne le trouvez pas dans votre boîte de réception, n'oubliez pas de vérifier votre dossier «Promotions» ou «Annonces», il vous y attend peut-être!",
    },
    {
        id: '7',
        question: 'Comment utiliser le chatbot?',
        answer: "Vous pouvez interagir avec Travy, notre assistant IA, pour obtenir des réponses à vos questions relatives aux voyages. Pour une expérience plus personnalisée, répondez à une enquête rapide lorsque vous utilisez Travy afin d'adapter les réponses et les recommandations à vos besoins spécifiques.",
    },
    {
        id: '8',
        question:
            'Puis-je recevoir des alertes en cas de danger dans la région où je séjourne?',
        answer: "Oui, c'est possible ! Il vous suffit de vous abonner aux notifications en temps réel dans votre profil, et vous recevrez des alertes sur les dangers ou les urgences à l'endroit où vous vous trouvez.",
    },
]

export const transaltesFAQSConfig = {
    [Languages.EN]: translateFAQEN,
    [Languages.DE]: translatesFAQDE,
    [Languages.ES]: translatesFAQES,
    [Languages.FR]: translatesFAQFR,
}
