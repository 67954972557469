import { useCallback, useState } from 'react'
import styles from './style.module.css'
import { InitConfig } from '@solidgate/react-sdk'
import { IApplyCouponError, IPaymentForm } from '../../typing/interfaces'
import { message } from 'antd'
import { PaymentCheckount } from '../../widgets'
import { useEventsListener } from '@/core/hooks'
import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import { useAccountStore, useLanguageStore } from '@/store'
import { saveSubscribeV2Req } from '@/modules/subscribe/api'

export const PaymentPage = () => {
    const { lang } = useLanguageStore()
    const { account } = useAccountStore()
    const [merchantData, setMerchantData] =
        useState<InitConfig['merchantData']>(null)
    const [isShow, setIsShow] = useState(false)
    const [typeSubscription, setSubscriptionType] = useState<SubscriptionType>()
    const [isTrial, setIsTrial] = useState(false)

    const [paymentStatus, setPaymentStatus] = useState<
        'idle' | 'fail' | 'success'
    >('idle')
    useEventsListener('payment', data => {
        setMerchantData(data.intent)
        setSubscriptionType(data.subscription)
        setIsShow(data.isShow)
        setIsTrial(data.isTrial)
    })

    const [paymentForm, setPaymentForm] = useState<IPaymentForm>(null)

    const handleOnReadyPaymentInstance = useCallback((form: IPaymentForm) => {
        setPaymentForm(form)
    }, [])

    const applyCode = async (val: string) => {
        try {
            await paymentForm.applyCoupon(val)
            message.success(`Coupon applied`)
        } catch (e) {
            const error = e as IApplyCouponError
            if (error.details) message.error(error.details.message)
        }
    }

    const resetPayment = async () => {
        const { data } = await saveSubscribeV2Req({
            subscriptionType: typeSubscription,
            lang,
            withTrial: isTrial,
        })
        setPaymentStatus('idle')
        setMerchantData(data?.intent as any)
    }

    if (!isShow) return null
    return (
        <div className={styles.container}>
            {merchantData && (
                <PaymentCheckount
                    resetPayment={resetPayment}
                    setPaymentStatus={setPaymentStatus}
                    paymentStatus={paymentStatus}
                    isTrial={isTrial}
                    canUsePromo={account?.canUseTrial}
                    applyCode={applyCode}
                    paymentInstance={handleOnReadyPaymentInstance}
                    merchantData={merchantData}
                    subscriptionPlan={typeSubscription}
                />
            )}
        </div>
    )
}
