import { useEffect, useMemo, useRef, useState } from 'react'
import styles from './styles.module.css'
import { Icon } from '@/core/components'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'

interface IProps {
    sendMessage: (val: string) => void
    loading: boolean
}
export const ChatFormControlAtom = ({ sendMessage, loading }: IProps) => {
    const { t } = useTranslation()
    const [value, setValue] = useState('')
    const [height, setHeight] = useState(54)
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        if (textAreaRef) {
            textAreaRef.current.style.height = '0px'
            const scrollHeight = textAreaRef.current.scrollHeight
            textAreaRef.current.style.height = scrollHeight + 'px'

            setHeight(scrollHeight)
        }
    }, [textAreaRef, value])

    const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        const val = evt.target?.value.trimStart()
        setValue(val)
    }

    const handleSendMessage = async () => {
        try {
            sendMessage(value)
        } catch (error) {
            Sentry.captureException(error)
        } finally {
            setValue('')
        }
    }

    const rightIndicator = useMemo(() => {
        if (loading) {
            return (
                <div className={styles.field_indicatort_container}>
                    <div className={styles.field_indicator} />
                </div>
            )
        } else if (!value) {
            return null
        }
        return (
            <div className={styles.send_container}>
                <Icon
                    name='send'
                    size={24}
                    color={value ? '#000000' : 'grey'}
                    onClick={value ? handleSendMessage : null}
                />
            </div>
        )
    }, [loading, value])

    return (
        <div className={styles.footer}>
            <div className={styles.field_container}>
                <textarea
                    placeholder={t('enterMessage')}
                    className={`${styles.styled_input} ${height > 54 ? styles.multi_line : styles.single_line}`}
                    value={value}
                    ref={textAreaRef}
                    style={{
                        maxHeight: 121,
                        overflow: height > 120 ? 'auto' : 'hidden',
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter' && value && !e.shiftKey) {
                            e.preventDefault()
                            handleSendMessage()
                        }
                    }}
                    onChange={handleChange}></textarea>

                <>{rightIndicator}</>
            </div>
            <p className={styles.footnote}>{t('notesChatBot')}</p>
        </div>
    )
}
