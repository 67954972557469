import styles from './styles.module.css'
import { Modal } from 'antd'
import { Icon, PrimaryButton } from '@/core/components'
import { useTranslation } from 'react-i18next'
import {
    consentTrial,
    consentWithoutTrial,
} from '../../configs/consents-translates'
import { useLanguageStore } from '@/store'

interface IProps {
    isOpen: boolean
    confirm: () => void
    close: () => void
    isTrial: boolean
    isOnce: boolean
    fullPrice: string
}
export const SubscriptionAgreementModal = ({
    isOpen,
    close,
    confirm,
    isTrial,
    isOnce,
    fullPrice,
}: IProps) => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()

    const getConsent = isTrial ? consentTrial : consentWithoutTrial
    const convertPriceNumber: number = parseFloat(fullPrice?.replace(',', '.'))
    const subscriptionCost = isOnce
        ? convertPriceNumber
        : convertPriceNumber * 3

    return (
        <Modal
            closeIcon={null}
            footer={null}
            classNames={{ content: styles.container }}
            styles={{ body: { width: '100%' } }}
            open={isOpen}>
            <Icon
                onClick={close}
                name='x'
                size={24}
                color='#CED3E1'
                style={{ position: 'absolute', top: 20, right: 20 }}
            />

            <div className={styles.content_modal}>
                <p className={styles.title}>
                    {t('subscriptionAgreementLabel')}
                </p>
                <p className={styles.secondary_txt}>
                    {getConsent[lang]({
                        fullPrice: `${subscriptionCost}`,
                        isOnceMonth: isOnce,
                    })}
                </p>

                <div className={styles.btn_group}>
                    <PrimaryButton
                        label={t('cancel')}
                        onClick={close}
                        className={styles.cancel_btn}
                        labelStyle={{
                            color: '#262933',
                        }}
                    />

                    <PrimaryButton
                        label={t('agree')}
                        onClick={confirm}
                        className={styles.confirm_btn}
                    />
                </div>
            </div>
        </Modal>
    )
}
