import { Icon, PrimaryButton } from '@/core/components'
import styles from './styles.module.css'
import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import { themeTariff } from '@/modules/subscribe/configs/advantes.config'
import { useTranslation } from 'react-i18next'

interface IProps {
    buyNow: () => void
    buyTrial: () => void
    typeSubscription: SubscriptionType
    hide: boolean
    canUseTrial?: boolean
    isActive: boolean
    isTrial?: boolean
    labelBtn: string
    hideTrial: boolean
}
export const ActionsTariffAtom = ({
    buyNow,
    buyTrial,
    typeSubscription,
    hide,
    isActive,
    labelBtn,
    hideTrial,
}: IProps) => {
    const { t } = useTranslation()
    const theme = themeTariff[typeSubscription]

    return (
        <div className={styles.container}>
            {!hide ? (
                <>
                    <PrimaryButton
                        style={{ backgroundColor: theme.primaryBtn }}
                        className={styles.primary_btn}
                        labelStyle={{
                            fontSize: 14,
                            fontWeight: '500',
                            color: theme.primaryBtnLabelColor,
                        }}
                        onClick={buyNow}
                        label={isActive ? t('fullSubsctiption') : labelBtn}
                        rightIcon={
                            <Icon
                                name='arrow-right'
                                size={16}
                                color={theme.primaryBtnLabelColor}
                            />
                        }
                    />
                    {hideTrial ? null : (
                        <PrimaryButton
                            style={{
                                backgroundColor: theme.secondaryBtn,
                            }}
                            className={styles.outline_btn}
                            labelStyle={{
                                fontSize: 14,
                                fontWeight: '500',
                                color: theme.secondaryBtnLabelColor,
                            }}
                            onClick={buyTrial}
                            label={t('grandTrial')}
                        />
                    )}
                </>
            ) : (
                <div style={{ height: 48, width: '100%' }} />
            )}
        </div>
    )
}
