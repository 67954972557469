import styles from './styles.module.css'
import { AnimationLoader, Icon } from '@/core/components'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { appEvents } from '@/core/events'
import { useEventsListener, useResizeWindow } from '@/core/hooks'
import {
    SubscriptionAgreementModal,
    SwitchTarrifs,
    TariffRedesign,
    UnpaidSubscriptionModal,
} from '../../components'
import {
    base,
    basic3Month,
    labelTariffs,
    premium,
    premium3Month,
    subscribeLabel,
    subscribeRedesignLabel,
} from '../../configs/advantes.config'
import { SubscriptionStatus, SubscriptionType } from '../../typing/enums'
import { useLanguageStore } from '@/store'
import { useAccount } from '@/modules/user/hooks'
import { sendEvent } from '@/core/services'
import * as Sentry from '@sentry/react'
import { saveSubscribeV2Req } from '../../api'
import dayjs from 'dayjs'

interface ISubscriptionAgreement {
    isOpen: boolean
    subscriptionType: SubscriptionType
    withTrial?: boolean
    isOnce: boolean
    price: string
}
export const SubscribeRedesignPage = () => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()
    const { width: windowWidth } = useResizeWindow()
    const { account } = useAccount()

    const isTrial =
        account?.subscription &&
        account?.subscription?.status === SubscriptionStatus.Trial

    const [subscriptionAgreement, setSubscriptionAgreement] =
        useState<ISubscriptionAgreement>(null)
    const [unpaidModal, setUnpaidModal] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const [modal, setModal] = useState<{
        isShow: boolean
        method: 'buy'
        isUpdate?: boolean
    }>({
        isShow: false,
        method: 'buy',
    })

    const [type, setType] = useState<'q' | 'm'>('q')

    useEventsListener('subsribe', (data: any) => {
        setModal({
            isShow: data?.isShow,
            method: data?.method,
            isUpdate: data?.isUpdate,
        })

        if (data?.isShow) {
            sendEvent('subscribes', {
                view_subscription_plans: data?.isShow,
            })
        }
    })

    const close = () => {
        appEvents.emit('subsribe', {
            isShow: false,
            method: null,
        })
        setType('q')
    }

    const chooseSubscribe = async (
        val: SubscriptionType,
        withTrial: boolean,
        isOnce?: boolean,
        price?: string,
    ) => {
        try {
            setIsLoading(true)
            const { data } = await saveSubscribeV2Req({
                subscriptionType: val,
                lang,
                withTrial,
            })
            setIsLoading(false)
            if (data?.intent) {
                setSubscriptionAgreement({
                    isOpen: false,
                    subscriptionType: null,
                    withTrial,
                    isOnce,
                    price,
                })
                appEvents.emit('payment', {
                    isShow: true,
                    intent: data?.intent,
                    subscription: val,
                    isTrial: withTrial,
                })
            }

            if (data?.url) {
                window.open(data.url, '_self')
            }
            close()
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    const handleCliskTariff = async ({
        val,
        cost,
        withTrial,
        isOnce,
    }: {
        val: SubscriptionType
        cost: string
        withTrial: boolean
        isOnce: boolean
    }) => {
        sendEvent('selected_subscription', {
            subscription_plan: subscribeLabel[val],
            subscription_price: cost,
        })

        if (account?.canUseTrial) {
            setSubscriptionAgreement({
                isOpen: true,
                subscriptionType: val,
                withTrial,
                isOnce: isOnce,
                price: cost,
            })
        } else if (
            account?.subscription?.status === SubscriptionStatus.Unpaid
        ) {
            setUnpaidModal(true)
        } else {
            await chooseSubscribe(val, account?.canUseTrial, isOnce, cost)
        }
    }

    const getEndDate = (expiredAt: string): string => {
        return dayjs(expiredAt).format('DD.MM.YYYY')
    }

    const getSubscridtionValidDate = () => {
        if (!account?.subscription) {
            return ''
        }
        if (account.subscription?.status === SubscriptionStatus.Trial) {
            const trialEndDate = dayjs(account?.subscription.createdAt).add(
                3,
                'days',
            )
            return trialEndDate.format('DD.MM.YYYY')
        } else {
            return getEndDate(account?.subscription?.expiredAt)
        }
    }

    const isMobile = windowWidth < 678
    if (!isMobile && !modal?.isShow) {
        return null
    }

    const labelBtnByStatus = (type: SubscriptionType) => {
        switch (true) {
            case account?.canUseTrial && !account?.subscription:
                return t(subscribeRedesignLabel[type])

            case !account?.canUseTrial && isTrial:
                return `${t('buy')} ${t(labelTariffs[type])} ${t('now')}`
            case !account?.canUseTrial && !isTrial:
                return `${t('buy')} ${t(labelTariffs[type])} ${t('now')}`
        }
    }

    const hideTrial = () => {
        switch (true) {
            case !account?.subscription && account?.canUseTrial:
                return false

            case !account?.subscription && !account?.canUseTrial:
                return true
            case account?.subscription && !account?.canUseTrial:
                return true
        }
    }

    return (
        <div
            className={`${styles.container} ${modal?.isShow ? styles?.visible : ''}`}>
            {isLoading ? <AnimationLoader /> : null}

            <div
                className={`${styles.right_container} ${modal?.isShow ? styles?.visible : ''}`}>
                <div className={styles.header}>
                    <div className={styles.header_content}>
                        <p className={styles.header_title}>
                            {t('subscriptionPlans')}
                        </p>
                        <p className={styles.subtitle_header}>
                            {`(*${t('editInAccount')})`}
                        </p>
                    </div>
                    <Icon
                        name='x'
                        size={18}
                        color='#868992'
                        onClick={close}
                        className={styles.cross}
                    />
                </div>

                <div className={styles.layout_content}>
                    <p className={styles.title}>
                        {t('titleTariffs')}{' '}
                        <span className={styles.sub_title}>
                            {t('withSafeTravy')}
                        </span>
                    </p>

                    <SwitchTarrifs value={type} onChange={setType} />

                    <div className={styles.content}>
                        {type === 'q' ? (
                            <>
                                <TariffRedesign
                                    hideTrial={hideTrial()}
                                    labelBtn={labelBtnByStatus(
                                        SubscriptionType.Basic_3m,
                                    )}
                                    canUseTrial={account?.canUseTrial}
                                    activeUntil={getSubscridtionValidDate()}
                                    isTrial={isTrial}
                                    isActive={
                                        account?.subscription &&
                                        account?.subscription?.type ===
                                            SubscriptionType.Basic_3m &&
                                        modal?.isUpdate
                                    }
                                    buyNow={() =>
                                        handleCliskTariff({
                                            val: SubscriptionType.Basic_3m,
                                            cost: '7,99',
                                            withTrial: false,
                                            isOnce: false,
                                        })
                                    }
                                    buyTrial={() =>
                                        handleCliskTariff({
                                            val: SubscriptionType.Basic_3m,
                                            cost: '7,99',
                                            withTrial: true,
                                            isOnce: false,
                                        })
                                    }
                                    typeSubscription={SubscriptionType.Basic_3m}
                                    advantes={basic3Month}
                                    label={t('basic3')}
                                    type={t('popular')}
                                    isPrimary={false}
                                    price='7,99'
                                    typePayment={t('monthly').toLowerCase()}
                                />

                                <TariffRedesign
                                    hideTrial={hideTrial()}
                                    labelBtn={labelBtnByStatus(
                                        SubscriptionType.Premium_3m,
                                    )}
                                    activeUntil={getSubscridtionValidDate()}
                                    isTrial={isTrial}
                                    isActive={
                                        account?.subscription &&
                                        account?.subscription?.type ===
                                            SubscriptionType.Premium_3m &&
                                        modal?.isUpdate
                                    }
                                    typeSubscription={
                                        SubscriptionType.Premium_3m
                                    }
                                    advantes={premium3Month}
                                    includeBasic
                                    label={t('extra3')}
                                    type={t('popular')}
                                    isPrimary={true}
                                    price='9,99'
                                    buyNow={() =>
                                        handleCliskTariff({
                                            val: SubscriptionType.Premium_3m,
                                            cost: '9,99',
                                            withTrial: false,
                                            isOnce: false,
                                        })
                                    }
                                    buyTrial={() =>
                                        handleCliskTariff({
                                            val: SubscriptionType.Premium_3m,
                                            cost: '9,99',
                                            withTrial: true,
                                            isOnce: false,
                                        })
                                    }
                                    typePayment={t('monthly').toLowerCase()}
                                />
                            </>
                        ) : (
                            <>
                                <TariffRedesign
                                    hideTrial={hideTrial()}
                                    labelBtn={labelBtnByStatus(
                                        SubscriptionType.Basic,
                                    )}
                                    activeUntil={getSubscridtionValidDate()}
                                    isTrial={isTrial}
                                    isActive={
                                        account?.subscription &&
                                        account?.subscription?.type ===
                                            SubscriptionType.Basic &&
                                        modal?.isUpdate
                                    }
                                    typeSubscription={SubscriptionType.Basic}
                                    advantes={base}
                                    label={t('basic')}
                                    type={t('popular')}
                                    isPrimary={false}
                                    price='8,99'
                                    buyNow={() =>
                                        handleCliskTariff({
                                            val: SubscriptionType.Basic,
                                            cost: '8,99',
                                            withTrial: false,
                                            isOnce: true,
                                        })
                                    }
                                    buyTrial={() =>
                                        handleCliskTariff({
                                            val: SubscriptionType.Basic,
                                            cost: '8,99',
                                            withTrial: true,
                                            isOnce: true,
                                        })
                                    }
                                    typePayment={t('monthly').toLowerCase()}
                                />

                                <TariffRedesign
                                    hideTrial={hideTrial()}
                                    labelBtn={labelBtnByStatus(
                                        SubscriptionType.Premium,
                                    )}
                                    activeUntil={getSubscridtionValidDate()}
                                    isTrial={isTrial}
                                    isActive={
                                        account?.subscription &&
                                        account?.subscription?.type ===
                                            SubscriptionType.Premium &&
                                        modal.isUpdate
                                    }
                                    typeSubscription={SubscriptionType.Premium}
                                    advantes={premium}
                                    includeBasic
                                    label={t('extra')}
                                    type={t('popular')}
                                    isPrimary={false}
                                    price='10,99'
                                    buyNow={() =>
                                        handleCliskTariff({
                                            val: SubscriptionType.Premium,
                                            cost: '10,99',
                                            withTrial: false,
                                            isOnce: true,
                                        })
                                    }
                                    buyTrial={() =>
                                        handleCliskTariff({
                                            val: SubscriptionType.Premium,
                                            cost: '10,99',
                                            withTrial: true,
                                            isOnce: true,
                                        })
                                    }
                                    typePayment={t('monthly').toLowerCase()}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
            <SubscriptionAgreementModal
                isOnce={subscriptionAgreement?.isOnce}
                fullPrice={subscriptionAgreement?.price}
                isTrial={subscriptionAgreement?.withTrial}
                isOpen={subscriptionAgreement?.isOpen}
                close={() =>
                    setSubscriptionAgreement({
                        isOpen: false,
                        subscriptionType: null,
                        withTrial: null,
                        price: null,
                        isOnce: null,
                    })
                }
                confirm={() =>
                    chooseSubscribe(
                        subscriptionAgreement?.subscriptionType,
                        subscriptionAgreement?.withTrial,
                    )
                }
            />
            <UnpaidSubscriptionModal
                isOpen={unpaidModal}
                close={() => setUnpaidModal(false)}
            />
        </div>
    )
}
