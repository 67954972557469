import { Modal } from 'antd'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useEventsListener } from '@/core/hooks'
import logo from '@/assets/images/guido_bot.png'
import { Icon } from '../../icons'
import { PrimaryButton } from '../../buttons'

export const Alert = () => {
    const { t } = useTranslation()

    const [modal, setModal] = useState<{
        isOpen: boolean
        title: string
        message: string
    }>()

    const closeModal = () => {
        setModal(null)
    }

    useEventsListener(
        'alert',
        data => {
            setModal({
                isOpen: data?.isShow,
                message: data?.message,
                title: data?.title,
            })
        },
        [],
    )

    const confirm = () => {
        setModal(null)
    }

    return (
        <Modal
            styles={{ body: { width: '100%' } }}
            closeIcon={null}
            footer={null}
            classNames={{ content: styles.container }}
            open={modal?.isOpen}>
            <div className={styles.header}>
                <Icon
                    onClick={closeModal}
                    name='x'
                    size={18}
                    color='#868992'
                    style={{ position: 'absolute', top: 12, right: 17 }}
                />
                <img src={logo} style={{ height: 36 }} />
                <p className={styles.title}>{t(modal?.title)}</p>
            </div>
            <div className={styles.content_modal}>
                <p className={styles.subtitle}>{t(modal?.message)}</p>
                <PrimaryButton
                    className={styles.btn}
                    labelStyle={{ fontSize: 14, fontWeight: '500' }}
                    label={'OK'}
                    onClick={confirm}
                />
            </div>
        </Modal>
    )
}
