import { Modal } from 'antd'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useEventsListener, useResizeWindow } from '@/core/hooks'
import { appEvents } from '@/core/events'
import logo from '@/assets/images/guido_bot.png'
import { Icon } from '../../icons'
import { PrimaryButton } from '../../buttons'
import { clearUrl } from '@/core/helpers'

export const StatusPayment = () => {
    const { t } = useTranslation()
    const { width: widthWindow } = useResizeWindow()

    const location = window.location?.href

    const [modal, setModal] = useState<{
        isOpen: boolean
        message: string
        status: 'succesed' | 'failed' | 'received'
        isIntro?: boolean
    }>()

    const isFailed = (val: string) => val.includes('failed')
    const isSucceded = (val: string) => val.includes('succeded')

    const closeModal = () => {
        if (modal?.isIntro) {
            if (widthWindow < 768) {
                appEvents.emit('intro', { isShow: true })
            } else {
                appEvents.emit('greeting', { isShow: true })
                localStorage.setItem('LEGEND', JSON.stringify(true))
            }
        }
        setModal(null)
        clearUrl(location)
    }

    useEventsListener(
        'statusPayment',
        data => {
            setModal({
                isOpen: data?.isShow,
                message: data?.message,
                status: data?.status,
                isIntro: data?.isIntro,
            })
        },
        [],
    )

    const statusTitle = {
        succesed: t('successPaidSubscribtion'),
        failed: t('transactionFailedFull'),
        received: t('modalTitle'),
    }

    const afterPaidFlow = () => {
        if (isSucceded(location)) {
            setModal({
                isOpen: true,
                status: 'succesed',
                message: 'upgradeSubscribtionMessage',
            })
        } else if (isFailed(location)) {
            setModal({
                isOpen: true,
                message: 'checkPayment',
                status: 'failed',
            })
        } else {
            setModal({ isOpen: false, message: null, status: null })

            return
        }
    }

    useEffect(() => {
        afterPaidFlow()
    }, [isSucceded(location), isFailed(location)])

    const confirm = () => {
        if (modal?.isIntro) {
            if (widthWindow < 768) {
                appEvents.emit('intro', { isShow: true })
            } else {
                appEvents.emit('greeting', { isShow: true })
                localStorage.setItem('LEGEND', JSON.stringify(true))
            }
        }
        setModal(null)
        clearUrl(location)
    }
    return (
        <Modal
            styles={{ body: { width: '100%' } }}
            closeIcon={null}
            footer={null}
            classNames={{ content: styles.container }}
            open={modal?.isOpen}>
            <div className={styles.header}>
                <Icon
                    onClick={closeModal}
                    name='x'
                    size={18}
                    color='#868992'
                    style={{ position: 'absolute', top: 12, right: 17 }}
                />
                <img src={logo} style={{ height: 36 }} />
                <p className={styles.title}>{statusTitle[modal?.status]}</p>
            </div>
            <div className={styles.content_modal}>
                <p className={styles.subtitle}>{t(modal?.message)}</p>
                <PrimaryButton
                    className={styles.btn}
                    labelStyle={{ fontSize: 14, fontWeight: '500' }}
                    label={t('gotYou')!}
                    onClick={confirm}
                />
            </div>
        </Modal>
    )
}
