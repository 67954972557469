import { Languages } from '@/core/typing'

interface IConsent {
    fullPrice: string
    isOnceMonth: boolean
}

const consentWithoutTrialEN = ({ fullPrice, isOnceMonth }: IConsent) => {
    return `By clicking “Buy“, you agree that you will be charged a full subscription price ${fullPrice} ${isOnceMonth ? 'per month' : 'per 3 months'}, based on your selected plan, until you cancel. You can cancel your subscription at any time by emailing our customer support team at support@safetravy.com or through your account settings.`
}

const consentWithoutTrialDE = ({ fullPrice, isOnceMonth }: IConsent) => {
    return `Wenn Sie auf „Kaufen“ klicken, erklären Sie sich damit einverstanden, dass Ihnen der volle Abonnementpreis ${fullPrice} ${isOnceMonth ? 'pro Monat' : 'pro 3 Monate'}, basierend auf dem von Ihnen gewählten Plan, berechnet wird, bis Sie kündigen. Sie können Ihr Abonnement jederzeit kündigen, indem Sie sich per E-Mail an unseren Kundendienst unter support@safetravy.com oder über Ihre Kontoeinstellungen wenden.`
}

const consentWithoutTrialESP = ({ fullPrice, isOnceMonth }: IConsent) => {
    return `Al hacer clic en «Comprar», acepta que se le cobre el precio total de la suscripción ${fullPrice} ${isOnceMonth ? 'al mes' : 'cada 3 meses'}, según el plan seleccionado, hasta que la cancele. Puede cancelar su suscripción en cualquier momento enviando un correo electrónico a nuestro equipo de atención al cliente a support@safetravy.com o a través de la configuración de su cuenta.`
}

const consentWithoutTrialFR = ({ fullPrice, isOnceMonth }: IConsent) => {
    return `En cliquant sur « Acheter », vous acceptez d'être facturé pour le prix total de l'abonnement ${fullPrice} ${isOnceMonth ? 'par mois' : 'par 3 mois'}, selon le plan choisi, jusqu'à ce que vous annuliez. Vous pouvez annuler votre abonnement à tout moment en envoyant un courriel à notre équipe d'assistance à la clientèle à l'adresse support@safetravy.com ou en utilisant les paramètres de votre compte.`
}

export const consentWithoutTrial = {
    [Languages.EN]: (props: IConsent) => consentWithoutTrialEN(props),
    [Languages.DE]: (props: IConsent) => consentWithoutTrialDE(props),
    [Languages.ES]: (props: IConsent) => consentWithoutTrialESP(props),
    [Languages.FR]: (props: IConsent) => consentWithoutTrialFR(props),
}

const consentTrialEN = ({ fullPrice, isOnceMonth }: IConsent) => {
    return `By clicking “Buy“, you agree that unless you cancel at least 24 hours before the end of the 3-day trial, you will be charged ${fullPrice} ${isOnceMonth ? 'per month' : 'per 3 months'}, based on your selected plan, until you cancel. You can cancel your subscription at any time by emailing our customer support team at support@safetravy.com or through your account settings.`
}

const consentTrialDE = ({ fullPrice, isOnceMonth }: IConsent) => {
    return `Wenn Sie auf „Kaufen“ klicken, erklären Sie sich damit einverstanden, dass Ihnen bis zu Ihrer Kündigung ${fullPrice} ${isOnceMonth ? 'pro Monat' : 'pro 3 Monate'}, basierend auf dem von Ihnen gewählten Tarif, in Rechnung gestellt wird, es sei denn, Sie kündigen mindestens 24 Stunden vor Ablauf der dreitägigen Testphase. Sie können Ihr Abonnement jederzeit kündigen, indem Sie sich per E-Mail an unser Kundendienstteam unter support@safetravy.com oder über Ihre Kontoeinstellungen wenden.`
}

const consentTrialESP = ({ fullPrice, isOnceMonth }: IConsent) => {
    return `Al hacer clic en «Comprar», acepta que, a menos que cancele la suscripción al menos 24 horas antes de que finalicen los 3 días de prueba, se le cobrará ${fullPrice} ${isOnceMonth ? 'al mes' : 'cada 3 meses'}, en función del plan seleccionado, hasta que cancele la suscripción. Puede cancelar su suscripción en cualquier momento enviando un correo electrónico a nuestro equipo de atención al cliente a support@safetravy.com o a través de la configuración de su cuenta.`
}

const consentTrialFR = ({ fullPrice, isOnceMonth }: IConsent) => {
    return `En cliquant sur « Acheter », vous acceptez que, sauf si vous annulez au moins 24 heures avant la fin de la période d'essai de 3 jours, vous serez facturé ${fullPrice} ${isOnceMonth ? 'par mois' : 'par 3 mois'}, selon le plan que vous avez choisi, jusqu'à ce que vous annuliez. Vous pouvez annuler votre abonnement à tout moment en envoyant un courriel à notre équipe d'assistance à la clientèle à l'adresse support@safetravy.com ou en utilisant les paramètres de votre compte.`
}

export const consentTrial = {
    [Languages.EN]: (props: IConsent) => consentTrialEN(props),
    [Languages.DE]: (props: IConsent) => consentTrialDE(props),
    [Languages.ES]: (props: IConsent) => consentTrialESP(props),
    [Languages.FR]: (props: IConsent) => consentTrialFR(props),
}
