import { providerGoogle, auth } from '@/config/firebase'
import { signInWithPopup } from 'firebase/auth'
import { socialAuthReq } from '../api'
import { useSession } from '@/core/hooks'
import * as Sentry from '@sentry/react'
import { sendEvent } from '@/core/services'

export const useSocialAuth = () => {
    const { saveSession } = useSession()

    const signInByGoogle = async () => {
        try {
            const resp: any = await signInWithPopup(auth, providerGoogle)
            const { data } = await socialAuthReq({
                idToken: resp._tokenResponse.idToken,
                deviceName: 'pc',
            })

            saveSession(data)
            if (data.newUserCreated) {
                sendEvent('successful_registration', {
                    registration_method: 'google',
                })
            }
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    const getSessionByFacebook = async (accessToken: string) => {
        try {
            const { data } = await socialAuthReq({
                idToken: accessToken,
                deviceName: 'pc',
                socialType: 'facebook',
            })
            saveSession(data)
            if (data.newUserCreated) {
                sendEvent('successful_registration', {
                    registration_method: 'facebook',
                })
            }
        } catch (error: any) {
            Sentry.captureException(error)
        }
    }
    return { signInByGoogle, getSessionByFacebook }
}
