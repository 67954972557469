import Payment, { InitConfig } from '@solidgate/react-sdk'
import { IPaymentForm, IProductPrice } from '../../typing/interfaces'
import { useState } from 'react'
import { styleCheckount } from './style.config'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { Icon, PrimaryButton, PromoCodeField } from '@/core/components'
import logo from '@/assets/images/full_logo.png'
import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import {
    labelTariffs,
    subscribeLabel,
} from '@/modules/subscribe/configs/advantes.config'
import { appEvents } from '@/core/events'
import { useSocketListener } from '@/core/hooks'
import { sendEvent } from '@/core/services'
interface IProps {
    paymentInstance: (form: IPaymentForm) => void
    merchantData: InitConfig['merchantData']
    applyCode: (val: string) => void
    subscriptionPlan: SubscriptionType
    canUsePromo: boolean
    isTrial: boolean
    paymentStatus: 'idle' | 'fail' | 'success'
    setPaymentStatus: (val: 'idle' | 'fail' | 'success') => void
    resetPayment: () => void
}
export const PaymentCheckount = ({
    paymentInstance,
    merchantData,
    applyCode,
    subscriptionPlan,
    canUsePromo,
    isTrial,
    paymentStatus,
    setPaymentStatus,
    resetPayment,
}: IProps) => {
    const { t } = useTranslation()
    const [product, setProduct] = useState<{
        discountPrice: IProductPrice
        productPrice: IProductPrice
    }>()
    const [code, setCode] = useState('')

    const subscriptionLabel = labelTariffs[subscriptionPlan]

    const goBack = () =>
        appEvents.emit('payment', {
            isShow: false,
            intent: null,
            subscription: null,
            isTrial: null,
        })

    useSocketListener(
        'subscription/new',
        () => {
            sendEvent('successful_subscription', {
                subscription_plan: subscribeLabel[subscriptionPlan],
                subscription_price: product?.productPrice.amount,
            })
        },
        [],
    )

    return (
        <div className={styles.container_form}>
            <div className={styles.content_form}>
                <div className={styles.header}>
                    <Icon
                        name='simple-arrow'
                        size={24}
                        color='#00000080'
                        onClick={goBack}
                    />
                    <img src={logo} style={{ width: 143 }} />
                    <div style={{ width: 10 }} />
                </div>
                {isTrial ? (
                    <p className={styles.title_left}>{t('threeDaysFree')}</p>
                ) : null}

                <p className={styles.subtitle_left}>
                    {isTrial ? t('then') : null}{' '}
                    {product?.discountPrice
                        ? product?.discountPrice.amount
                        : product?.productPrice.amount}{' '}
                    EUR {t('totalSubscriptionPeriod')}
                </p>

                <p>
                    {t('subscribeTo')} {t(subscriptionLabel)} plan
                </p>

                <p>{t('fullAccess')}</p>
                {canUsePromo ? (
                    <>
                        <PromoCodeField
                            value={code}
                            onChangeField={setCode}
                            apply={() => applyCode(code)}
                            style={{ marginTop: 32 }}
                        />
                        <p className={styles.promo_code_description}>
                            <strong>{t('warning')}: </strong>
                            {t('promoCodeDescription')}
                        </p>
                    </>
                ) : null}
            </div>

            <div className={styles.devider} />

            <div className={styles.content_right_form}>
                <Payment
                    onFail={() => {
                        setPaymentStatus('fail')
                    }}
                    width='100%'
                    onSuccess={() => {
                        setPaymentStatus('success')
                        appEvents.emit('afterPaid', { isShow: true })
                    }}
                    onError={() => {
                        setPaymentStatus('fail') // Встановлюємо статус "fail"
                    }}
                    onReadyPaymentInstance={paymentInstance}
                    merchantData={merchantData}
                    styles={styleCheckount}
                    onPaymentDetails={e => {
                        setProduct(e.payment.priceBreakdown as any)
                    }}
                    formParams={{
                        titleText: t('payCard'),
                        cardNumberLabel: t('cardNumber'),
                        cardExpiryDateLabel: t('expiryDate'),
                        submitButtonText: isTrial ? t('startTrial') : t('buy'),
                        isCardHolderVisible: true,

                        googleFontLink:
                            '//fonts.googleapis.com/css2?family=DM+Sans:ital@1&display=swap',
                        autoFocus: true,
                    }}
                />

                {paymentStatus === 'fail' ? (
                    <PrimaryButton
                        style={{ width: '100%' }}
                        onClick={resetPayment}
                        label='Try again'
                    />
                ) : (
                    <p className={styles.consent}>{t('consent')}</p>
                )}

                <p className={`${styles.consent} ${styles.row}`}>
                    <Icon name='shield-tick' size={20} />
                    {t('paymentSecured')}
                </p>
            </div>
        </div>
    )
}
