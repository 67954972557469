import { PrimaryButton } from '@/core/components'
import styles from './styles.module.css'
import { appEvents } from '@/core/events'
import { createStyleSheet } from '@/core/helpers'
import { CancelSubscribeModal } from '../../components'
import { useEffect, useState } from 'react'
import { cancelSubsriptionReq, getSubscribeHistoryReq } from '../../api'
import { useAccount } from '@/modules/user/hooks'
import { CurrentSubscribeAtom, MobileFooterSubscribeAtom } from './atoms'
import { SubscribesHistoryAtom } from './atoms/subscribes-history'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'
import { SubscriptionStatus } from '../../typing/enums'
import dayjs from 'dayjs'

export const UserSubscribeWidget = () => {
    const { t } = useTranslation()

    const { getAccount, account } = useAccount()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [history, setHistory] = useState<any>([])

    const closeModal = () => setIsModalOpen(false)

    const openModal = () => setIsModalOpen(true)

    const getHistorySubscribe = async () => {
        try {
            const { data } = await getSubscribeHistoryReq()
            setHistory(
                data.map((it: any) => ({
                    date: it.createdAt,
                    type: it.type,
                })),
            )
        } catch (error) {
            Sentry.captureException(error)
        }
    }
    useEffect(() => {
        getHistorySubscribe()
    }, [])

    const cancelSubscribe = async () => {
        try {
            await cancelSubsriptionReq()

            setIsModalOpen(false)
            appEvents.emit('statusPayment', {
                isShow: true,
                status: 'received',
                message: 'successCancelSubscribtion',
            })
            await getAccount()
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    const updateSubsription = () => {
        appEvents.emit('subsribe', {
            isShow: true,
            method: 'buy',
            isUpdate: true,
        })
    }

    const getEndDate = (expiredAt: string): string => {
        return dayjs(expiredAt).format('DD.MM.YYYY')
    }

    const getSubscridtionValidDate = () => {
        if (!account?.subscription) {
            return ''
        }
        if (account.subscription?.status === SubscriptionStatus.Trial) {
            const trialEndDate = dayjs(account?.subscription.createdAt).add(
                3,
                'days',
            )
            return trialEndDate.format('DD.MM.YYYY')
        } else {
            return getEndDate(account?.subscription?.expiredAt)
        }
    }
    return (
        <div className={styles.container} id='account-subscription-section'>
            <div className={styles.header_form}>
                <p className={styles.label_form}>{t('subscription')}</p>
                <div className={styles.btn_group}>
                    <PrimaryButton
                        disabled={
                            !!account?.subscription?.cancellationScheduled
                        }
                        className={styles.cancel_btn}
                        labelStyle={inlineStyles.labelCancel}
                        label={t('cancelSubscribtionBtn')}
                        onClick={openModal}
                    />

                    <PrimaryButton
                        className={styles.primary_btn}
                        label={t('updateSubscriptionBtn')}
                        labelStyle={inlineStyles.label}
                        onClick={updateSubsription}
                    />
                </div>
            </div>

            <CurrentSubscribeAtom
                subscribe={account?.subscription?.type}
                activePeriod={getSubscridtionValidDate()}
            />

            <SubscribesHistoryAtom history={history} />

            <CancelSubscribeModal
                close={closeModal}
                isOpen={isModalOpen}
                confirm={cancelSubscribe}
            />
            <MobileFooterSubscribeAtom
                isCanceled={!!account?.subscription?.cancellationScheduled}
                cancelSubscribe={openModal}
                updateSubscribe={updateSubsription}
            />
        </div>
    )
}

const inlineStyles = createStyleSheet({
    labelCancel: { color: '#97999A', fontSize: 14, fontWeight: '600' },
    label: { fontSize: 14, fontWeight: '600' },
})
