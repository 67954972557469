import Lottie from 'lottie-react'
import earth from './earth_1.json'
import { createStyleSheet } from '@/core/helpers'

export const AnimationLoader = () => {
    return (
        <div style={styles.container}>
            <Lottie animationData={earth} style={styles.animContainer} />
        </div>
    )
}

const styles = createStyleSheet({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        position: 'absolute',
        zIndex: 99999,
        overflow: 'hidden',
    },
    animContainer: {
        height: 200,
        width: 200,
    },
})
