import styles from './styles.module.css'
import { Icon } from '@/core/components'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { appEvents } from '@/core/events'
import { useEventsListener } from '@/core/hooks'
import { SwitchTarrifs, TariffRedesign } from '../../components'
import {
    base,
    basic3Month,
    premium,
    premium3Month,
    subscribeRedesignLabel,
} from '../../configs/advantes.config'
import { SubscriptionType } from '../../typing/enums'
import { useNavigate } from 'react-router-dom'
import { AuthStep } from '@/modules/auth/typing'

export const TariffPlansRedesignPage = () => {
    const { t } = useTranslation()
    const [type, setType] = useState<'q' | 'm'>('q')
    const navigate = useNavigate()
    const [modal, setModal] = useState<{
        isShow: boolean
    }>({
        isShow: false,
    })

    const close = () => {
        appEvents.emit('previewTariffs', {
            isShow: false,
        })
        setType('q')
    }

    const goRegister = () => {
        navigate('/auth', { state: { step: AuthStep.Register } })
    }
    useEventsListener(
        'previewTariffs',
        (data: any) => {
            setModal({
                isShow: data?.isShow,
            })
        },
        [],
    )

    return (
        <div
            className={`${styles.container} ${modal?.isShow ? styles?.visible : ''}`}>
            <div
                className={`${styles.right_container} ${modal?.isShow ? styles?.visible : ''}`}>
                <div className={styles.header}>
                    <div className={styles.header_content}>
                        <p className={styles.header_title}>
                            {t('subscriptionPlans')}
                        </p>
                        <p className={styles.subtitle_header}>
                            {t('editInAccount')}
                        </p>
                    </div>
                    <Icon
                        name='x'
                        size={18}
                        color='#868992'
                        onClick={close}
                        className={styles.cross}
                    />
                </div>
                <div className={styles.layout_content}>
                    <p className={styles.title}>
                        {t('titleTariffs')}{' '}
                        <span className={styles.sub_title}>
                            {t('withSafeTravy')}
                        </span>
                    </p>

                    <SwitchTarrifs value={type} onChange={setType} />
                    <div className={styles.content}>
                        {type === 'q' ? (
                            <>
                                <TariffRedesign
                                    labelBtn={t(
                                        subscribeRedesignLabel[
                                            SubscriptionType.Basic_3m
                                        ],
                                    )}
                                    hideTrial={false}
                                    buyNow={goRegister}
                                    buyTrial={goRegister}
                                    typeSubscription={SubscriptionType.Basic_3m}
                                    advantes={basic3Month}
                                    label={t('basic3')}
                                    type={t('popular')}
                                    isPrimary={false}
                                    price='7,99'
                                    typePayment={t('monthly').toLowerCase()}
                                />

                                <TariffRedesign
                                    labelBtn={t(
                                        subscribeRedesignLabel[
                                            SubscriptionType.Premium_3m
                                        ],
                                    )}
                                    hideTrial={false}
                                    typeSubscription={
                                        SubscriptionType.Premium_3m
                                    }
                                    advantes={premium3Month}
                                    includeBasic
                                    label={t('extra3')}
                                    type={t('popular')}
                                    isPrimary={true}
                                    price='9,99'
                                    buyNow={goRegister}
                                    buyTrial={goRegister}
                                    typePayment={t('monthly').toLowerCase()}
                                />
                            </>
                        ) : (
                            <>
                                <TariffRedesign
                                    labelBtn={t(
                                        subscribeRedesignLabel[
                                            SubscriptionType.Basic
                                        ],
                                    )}
                                    hideTrial={false}
                                    typeSubscription={SubscriptionType.Basic}
                                    advantes={base}
                                    label={t('basic')}
                                    type={t('popular')}
                                    isPrimary={false}
                                    price='8,99'
                                    buyNow={goRegister}
                                    buyTrial={goRegister}
                                    typePayment={t('monthly').toLowerCase()}
                                />

                                <TariffRedesign
                                    labelBtn={t(
                                        subscribeRedesignLabel[
                                            SubscriptionType.Premium
                                        ],
                                    )}
                                    hideTrial={false}
                                    typeSubscription={SubscriptionType.Premium}
                                    advantes={premium}
                                    includeBasic
                                    label={t('extra')}
                                    type={t('popular')}
                                    isPrimary={false}
                                    price='10,99'
                                    buyNow={goRegister}
                                    buyTrial={goRegister}
                                    typePayment={t('monthly').toLowerCase()}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
