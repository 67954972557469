import styles from './styles.module.css'
import { ActionsTariffAtom, HeadertariffAtom } from './atoms'
import { AdvantesAtomRedesign } from './atoms/advantes'
import { SubscriptionType } from '../../typing/enums'
import _ from 'lodash'
import { themeTariff } from '../../configs/advantes.config'
import { useTranslation } from 'react-i18next'
import { Icon } from '@/core/components'

interface IProps {
    label: string
    price: string
    buyNow: () => void
    buyTrial: () => void
    advantes: string[]
    includeBasic?: boolean
    type: SubscriptionType
    isPrimary?: boolean
    typePayment: string
    typeSubscription: SubscriptionType
    isTrial?: boolean
    isActive?: boolean
    activeUntil?: string
    canUseTrial?: boolean
    labelBtn: string
    hideTrial: boolean
}

export const TariffRedesign = ({
    label,
    price,
    type,
    advantes,
    includeBasic,
    typePayment,
    isPrimary,
    typeSubscription,
    buyNow,
    buyTrial,
    activeUntil,
    isActive,
    isTrial,
    canUseTrial,
    labelBtn,
    hideTrial,
}: IProps) => {
    const theme = themeTariff[typeSubscription]
    const { t } = useTranslation()

    return (
        <div
            className={styles.container}
            style={{
                backgroundColor: theme.background,
                border:
                    isActive && isTrial && !canUseTrial
                        ? '2px solid #0047EE'
                        : 'none',
            }}>
            <HeadertariffAtom
                typeSubscription={typeSubscription}
                isPrimary={isPrimary}
                typeTariff={label}
                price={price}
                typePayment={typePayment}
            />

            <AdvantesAtomRedesign
                typeSubscription={typeSubscription}
                type={type}
                allBasic={includeBasic}
                list={advantes}
            />

            <ActionsTariffAtom
                hideTrial={hideTrial}
                labelBtn={labelBtn}
                isActive={isActive}
                canUseTrial={canUseTrial}
                hide={isActive && !isTrial}
                typeSubscription={typeSubscription}
                buyNow={buyNow}
                buyTrial={buyTrial}
                isTrial={isTrial}
            />
            {!isActive || isTrial ? null : (
                <div className={styles.current_container}>
                    <div className={styles.current_indicator}>
                        <Icon
                            name='tick-circle-full'
                            color='#FFFFFF'
                            size={22}
                        />
                        <p style={{ color: '#FFFFFF' }}>{t('current')}</p>
                    </div>
                    <p style={{ color: theme.activeUntil }}>
                        {t('activeUntil')}{' '}
                        <span style={{ color: theme.date }}>
                            {activeUntil}.
                        </span>
                    </p>
                </div>
            )}
        </div>
    )
}
